































































































































































import Vue from 'vue';
import { statsViewModule } from '@/store/viewModule/stats/statsViewModule';
import { completionAndHourModule } from '@/store/viewModule/stats/chartModule/completionAndHourChartModule';
import { completionByGroupModule } from '@/store/viewModule/stats/chartModule/completionByGroupChartModule';
import { completionByTagModule } from '@/store/viewModule/stats/chartModule/completionByTagChartModule';
import { tasksByDayModule } from '@/store/viewModule/stats/chartModule/tasksByDayChartModule';
import { tasksByTagModule, TasksByTagState } from '@/store/viewModule/stats/chartModule/tasksByTagChartModule';

// components
import { GChart } from 'vue-google-charts';
import UserTaskCalendarContainer from '@/container/stats/UserTaskCalendarContainer.vue';
import UserTableContainer from '@/container/stats/UserTableContainer.vue';
import { DailyReportResponse } from 'remonade-api/lib';
import { channelsModule } from '@/store/dataModule/channel/channelsModule';

export default Vue.extend({
  name: 'StatsContainer',

  components: {
    GChart,
    UserTaskCalendarContainer,
    UserTableContainer,
  },

  computed: {
    rangeLabel(): string {
      return statsViewModule.startDate + ' ~ ' + statsViewModule.endDate;
    },
    comparisonRangeLabel(): string {
      return statsViewModule.comparisonStartDate + ' ~ ' + statsViewModule.comparisonEndDate;
    },
    selectedChannelId() {
      return statsViewModule.selectedChannelId;
    },
    selectedChannelName() {
      if (this.selectedChannelId) {
        const find = channelsModule.channels.find((channel) => channel.channelId === this.selectedChannelId);
        if (find) {
          return find.channelName;
        }
      }
      return null;
    },
    // Number box
    // completion
    channelCompletion() {
      return (statsViewModule.channelCompletion).toFixed(1);
    },
    channelCompletionComparison() {
      return (statsViewModule.channelCompletionComparison).toFixed(1);
    },
    completionDiff(): number {
      return statsViewModule.channelCompletion - statsViewModule.channelCompletionComparison;
    },
    completionDiffClass(): string {
      return this.completionDiff > 0 ? 'primary--text' : 'danger--text';
    },
    // tasks
    channelTasksCount(): number {
      return statsViewModule.channelTasksCount;
    },
    channelTasksCountComparison(): number {
      return statsViewModule.channelTasksCountComparison;
    },
    tasksDiff(): number {
      return this.channelTasksCount - this.channelTasksCountComparison;
    },
    // average hour
    averageHour(): number {
      return statsViewModule.channelAverageHour;
    },
    averageHourComparison(): number {
      return statsViewModule.channelAverageHourComparison;
    },
    averateHourDiff(): number {
      return this.averageHour - this.averageHourComparison;
    },
    // chart
    completionAndHourData() {
      return completionAndHourModule.completionAndHourData;
    },
    tasksByTagData() {
      return tasksByTagModule.tasksByTagData;
    },
    tasksByDayData() {
      return tasksByDayModule.tasksByDayData;
    },
    completionByGroup() {
      return completionByGroupModule.completionByGroup;
    },
    completionByTagData() {
      return completionByTagModule.completionByTagData;
    },
    tasksByTagOptions() {
      return statsViewModule.tasksByTag.filter((tbt) => tbt.reports.length > 0);
    },
    hiddenTagIdsForCompletionByTag() {
      return completionByTagModule.hiddenTagIds;
    },
    completionByTagTags() {
      return statsViewModule.tasksByTag.filter((tbt) => {
        const find = completionByTagModule.hiddenTagIds.find((tagId) => tbt.tag.id === tagId);
        if (find) {
          return false;
        }
        if (tbt.reports.length > 0) {
          return true;
        }
        return false;
      }).map((tbt) => tbt.tag);
    },
    completionByTagTagOptions() {
      return statsViewModule.tasksByTag.filter((tbt) => tbt.reports.length > 0).map((tbt) => tbt.tag);
    },
  },

  methods: {
    inputHiddenTagIdForCompletionByTag(value: string[]) {
      const hiddenTagIds = this.completionByTagTagOptions.filter((option) => {
        return !(value.indexOf(option.id) >= 0);
      }).map((option) => option.id);

      completionByTagModule.setHiddenTagId(hiddenTagIds);
    },
    removeHiddenTagIdForCompletionByTag(value: string) {
      completionByTagModule.addHiddenTagId(value);
    },
    isTagHiddenForComletionByTag(tagId: string) {
      const find = completionByTagModule.hiddenTagIds.find((id) => id === tagId);
      if (find) {
        return true;
      }
      return false;
    },
    reloadStatsData() {
      statsViewModule.getStatsData(true);
    }
  },
});
