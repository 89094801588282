



























































import Vue from 'vue';
import { statsViewModule } from '@/store/viewModule/stats/statsViewModule';
import { DailyReportResponse } from 'remonade-api/lib';
import { tagModule } from '@/store/dataModule/tag/tagModule';
import dayjs from 'dayjs';
import { isMobile } from '@/service/StyleService';

export default Vue.extend({
  name: 'UserTaskCalendarContainer',

  components: {
  },

  data() {
    return {
      showWeekends: false,
    };
  },

  computed: {
    selectedChannelReports(): DailyReportResponse[] {
      return statsViewModule.selectedChannelReports;
    },
    startDate() {
      return statsViewModule.startDate;
    },
    endDate() {
      return statsViewModule.endDate;
    },
    tags() {
      return tagModule.activatedTags;
    },
    currentDate() {
      return dayjs().format('YYYY-MM-DD');
    },
    weekdays() {
      if (this.showWeekends) {
        return [0, 1, 2, 3, 4, 5, 6];
      }
      return [1, 2, 3, 4, 5];
    },
    minDate() {
      return statsViewModule.minDate;
    },
    isMobile() {
      return isMobile(window);
    },
  },

  methods: {
    getDateTasks(date: string) {
      return this.selectedChannelReports
              .filter((r) => (r.type === 'task') && (r.date === date) && (r.parentId === null))
              .sort((t1, t2) => (t1.displayOrder || 0) - (t2.displayOrder || 0));
    },
    getTag(tagId: string, key: string) {
      const find = this.tags.find((tag) => tag.id === tagId);
      if (find) {
        if (key === 'name') {
          return find.label;
        } else if (key === 'color') {
          return find.color;
        }
        return null;
      }
      return null;
    },
    isDateInRange(dateString: string) {
      const date = dayjs(dateString);
      if (!this.minDate) {
        return true;
      } else {
        const minDate = dayjs(this.minDate);
        if (date.diff(minDate) >= 0) {
          return true;
        }
        return false;
      }
    },
  },
});
