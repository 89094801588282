import { render, staticRenderFns } from "./UserTaskCalendarContainer.vue?vue&type=template&id=4e46e4c1&scoped=true&"
import script from "./UserTaskCalendarContainer.vue?vue&type=script&lang=ts&"
export * from "./UserTaskCalendarContainer.vue?vue&type=script&lang=ts&"
import style0 from "./UserTaskCalendarContainer.vue?vue&type=style&index=0&id=4e46e4c1&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e46e4c1",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCalendar } from 'vuetify/lib/components/VCalendar';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VCalendar,VSwitch})
