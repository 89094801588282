import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';

import { getTasksByTagData, getTasksByTagDataWithStats } from '@/service/ui/StatsService';
import { statsViewModule } from '@/store/viewModule/stats/statsViewModule';
import { tagModule } from '@/store/dataModule/tag/tagModule';

export interface TasksByTagState {
}

@Module({ dynamic: true, store, name: 'tasks-by-tag-chart', namespaced: true })
class TasksByTagModule extends VuexModule implements TasksByTagState {
  public get tasksByTagData() {
    // calc task hours by tag
    return getTasksByTagDataWithStats(statsViewModule.selectedChannelTagStats, tagModule.activatedTags);
  }
}

export const tasksByTagModule = getModule(TasksByTagModule);
